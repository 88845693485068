import _client from "utilise/client";
import _rijs from "rijs.core";
import _rijs2 from "rijs.singleton";
import _rijs3 from "rijs.data";
import _rijs4 from "rijs.css";
import _rijs5 from "rijs.fn";
import _rijs6 from "rijs.sync";
import _rijs7 from "rijs.components";
import _path from "path";
import _rijs8 from "rijs.sessions";
import _rijs9 from "rijs.serve";
import _rijs10 from "rijs.pages";
import _rijs11 from "rijs.resdir";
var exports = {};
const client = _client;
if (client) !window.ripple && create();
exports = create;

function create(opts) {
  const ripple = _rijs(opts);

  _rijs2(ripple, opts);

  _rijs3(ripple, opts);

  _rijs4(ripple, opts);

  _rijs5(ripple, opts);

  _rijs6(ripple, opts);

  _rijs7(ripple, opts);

  if (!client) {
    opts.serve = _path.resolve(new URL(import.meta.url.slice(0, import.meta.url.lastIndexOf("/"))).pathname, "client");

    _rijs8(ripple, opts);

    _rijs9(ripple, opts);

    _rijs10(ripple, opts);

    _rijs11(ripple, opts);
  }

  return ripple;
}

export default exports;